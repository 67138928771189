<template>
    <div class="form-company">
        <el-form>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="ID">
                        {{currentCompany.id}}
                    </el-form-item>
                    <el-form-item label="Aloware ID">
                        {{currentCompany.aloware_id}}
                    </el-form-item>
                    <el-form-item label="Company Name">
                        <el-input v-model="currentCompany.name"></el-input>
                    </el-form-item>
                    <el-form-item label="Is Importing">
                        <el-input v-model="currentCompany.is_importing"></el-input>
                    </el-form-item>
                    <el-form-item label="Is Mapped">
                        <el-input v-model="currentCompany.is_mapped"></el-input>
                    </el-form-item>
                    <el-form-item label="Slug">
                        <el-input v-model="currentCompany.slug"></el-input>
                    </el-form-item>
                    <el-form-item label="External Credit Applicaiton Url">
                        <el-input v-model="currentCompany.external_credit_application_url"></el-input>
                    </el-form-item>
                    <el-form-item label="Dealervault FTP Folder">
                        <el-input v-model="currentCompany.dealervault_ftp_folder"></el-input>
                    </el-form-item>
                    <el-form-item label="Inventory Provider">
                        <el-select v-model="currentCompany.company_car_data_source_id"
                            remote
                            filterable
                            clearable
                            :remote-method="loadCompanyCarDataSources"
                            @change="handleCompanyCarDataSourcesChange"
                            placeholder="Select Provider"
                            style="width: auto">
                            <el-option
                                v-for="item in company_car_data_sources"
                                :key="item.id"
                                :label="item.name + ' (Type: ' + item.data_type + ')'"
                                :value="item.id">
                                <span style="float: left">{{ item.name }} (Type: {{ item.data_type }})</span>
                                <span style="float: right">
                                    <i class="el-icon-edit" title="Edit Provider" @click="handleUpdateProvider(item)"></i>
                                </span>
                            </el-option>
                        </el-select>

                        <el-button type="secondary" @click="handleAddProvider()" icon="el-icon-plus">Add Provider</el-button>
                    </el-form-item>
                    <el-form-item label="CSV Filename">
                        <el-input v-model="currentCompany.csv_filename"></el-input>
                    </el-form-item>
                    <el-form-item label="CSV Column Value" v-if="currentDataSource.data_type=='3'">
                        <el-input v-model="currentCompany.csv_dealership_value"></el-input>
                    </el-form-item>
                    <el-form-item label="FTP Dir">
                        <el-input v-model="currentCompany.ftp_dir"></el-input>
                    </el-form-item>
                    <el-form-item label="FTP Username">
                        <el-input v-model="currentCompany.ftp_username"></el-input>
                    </el-form-item>
                    <el-form-item label="FTP Password">
                        <el-input v-model="currentCompany.ftp_password"></el-input>
                    </el-form-item>
                    <el-form-item label="Notes">
                        <el-input
                            type="textarea"
                            :rows="2"
                            v-model="currentCompany.notes">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Meta">
                        <el-button @click="addMeta">Add meta <i class="el-icon-plus"></i></el-button>
                        <div>
                            <el-row :gutter="0" v-for="meta_item in this.currentCompany.meta"
                                :key="meta_item.id">
                                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                                    {{meta_item.key}}
                                </el-col>
                                <el-col :xs="11" :sm="5" :md="5" :lg="5" :xl="5">
                                    {{meta_item.value}}
                                </el-col>
                                <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                                    <el-button @click="updateMeta(meta_item)"><i class="el-icon-edit"></i></el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                    <el-collapse>
                        <el-collapse-item title="Other Fields" name="4">
                            <el-row :gutter="10">
                                <el-col :span="6">
                                    <el-form-item label="Min Pics">
                                        <el-input v-model="currentCompany.fb_feed_min_pics"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="Min Price">
                                        <el-input v-model="currentCompany.fb_feed_min_price"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="Max Price">
                                        <el-input v-model="currentCompany.fb_feed_max_price"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="Car in stock days offset">
                                        <el-input-number
                                            v-model="currentCompany.fb_feed_car_in_stock_days_offset"
                                            controls-position="right"
                                            :min="0"
                                        >
                                        </el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="Logo">
                                <el-input v-model="currentCompany.logo"></el-input>
                            </el-form-item>
                            <el-form-item label="Importing End">
                                <el-input v-model="currentCompany.importing_end"></el-input>
                            </el-form-item>
                            <el-form-item label="Importing Start">
                                <el-input v-model="currentCompany.importing_start"></el-input>
                            </el-form-item>
                            <el-form-item label="Monthly Payment Calculation"><br>
                                <el-input v-model="currentCompany.monthly_payment_calculation"></el-input>
                            </el-form-item>
                            <el-form-item label="Timezone">
                                <el-input v-model="currentCompany.timezone"></el-input>
                            </el-form-item>
                            <el-form-item label="Address">
                                <el-input v-model="currentCompany.address"></el-input>
                            </el-form-item>
                            <el-form-item label="Longitude">
                                <el-input v-model="currentCompany.longitude"></el-input>
                            </el-form-item>
                            <el-form-item label="Latitude">
                                <el-input v-model="currentCompany.latitude"></el-input>
                            </el-form-item>
                            <el-form-item label="Postal Code">
                                <el-input v-model="currentCompany.postal_code"></el-input>
                            </el-form-item>
                            <el-form-item label="Region">
                                <el-input v-model="currentCompany.region"></el-input>
                            </el-form-item>
                            <el-form-item label="City">
                                <el-input v-model="currentCompany.city"></el-input>
                            </el-form-item>
                            <el-form-item label="Representative Name">
                                <el-input v-model="currentCompany.representative_name"></el-input>
                            </el-form-item>
                            <el-form-item label="Representative Phone">
                                <el-input v-model="currentCompany.representative_phone"></el-input>
                            </el-form-item>
                            <el-form-item label="FB Page Id">
                                <el-input v-model="currentCompany.fb_page_id"></el-input>
                            </el-form-item>
                            <el-form-item label="Website">
                                <el-input v-model="currentCompany.website"></el-input>
                            </el-form-item>
                            <el-form-item label="Created">
                                <el-input v-model="currentCompany.created_at"></el-input>
                            </el-form-item>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <el-form-item label="">
                                        <el-checkbox v-model="currentCompany.inventory_to_gocrm">Inventory To GOCRM</el-checkbox>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="GOCRM Host">
                                        <el-input v-model="currentCompany.gocrm_host"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="GOCRM Username">
                                        <el-input v-model="currentCompany.gocrm_username"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="GOCRM Password">
                                        <el-input v-model="currentCompany.gocrm_password"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="margin-top: 15px">
            <el-col :span="12">
                <el-button @click="$emit('closeDialog')">Cancel</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" @click="handleSubmit">Submit</el-button>
            </el-col>
        </el-row>
        <el-dialog title="Inventory Provider Form" :visible.sync="companyCarDatasourceFormVisible" append-to-body>
            <CompanyCarDatasourceForm
                :company_car_data_source="selectedDataSource"
                v-on:onSuccessfulSubmit="handleCompanyCarDatasourceFormSuccess"
                v-on:closeDialog="companyCarDatasourceFormVisible = false"/>
        </el-dialog>
        <el-dialog :title="'Company Meta for ' + currentCompany.name" :visible.sync="companyMetaFormVisible" append-to-body>
            <CompanyMetaForm
                :current_company="currentCompany"
                :current_meta="selectedMeta"
                v-on:onSuccessfulSubmit="handleCompanyMetaFormSuccess"
                v-on:closeDialog="companyMetaFormVisible = false"/>
        </el-dialog>
    </div>
</template>

<script>
import CompanyCarDatasourceForm from '../CompanyCarDatasourceForm';
import CompanyMetaForm from '../CompanyMetaForm';

export default {
    props: ['company'],
    components: {
        CompanyCarDatasourceForm,
        CompanyMetaForm,
    },
    data() {
        return {
            currentCompany: {},
            currentDataSource: {
                data_type: 1
            },
            company_car_data_sources: [],
            selectedDataSource: {
                id: '',
                name: '',
                email_address: '',
                phone_number: '',
                directory: '',
                dealership_column_header: '',
            },
            selectedMeta: {},
            companyCarDatasourceFormVisible: false,
            companyMetaFormVisible: false,
            companyMeta: [],
        };
    },
    computed: {},
    mounted() {
        this.currentCompany = this.company
        this.getLatestCompanyData()
        this.loadCompanyCarDataSources()
    },
    methods: {
        getLatestCompanyData() {
            var companyId = this.currentCompany.aloware_id
            if(this.currentCompany.aloware_id == '0') {
                companyId = 'simpid-' + this.currentCompany.id
            }
            this.axios
            .get('/api/company/' + companyId, this.currentCompany)
            .then(response => {
                if (response.data.success == true) {
                    this.currentCompany = response.data.data
                    this.currentDataSource = this.currentCompany.company_car_data_source != null ? this.currentCompany.company_car_data_source : this.currentDataSource;
                    this.companyMeta = this.currentCompany.meta != null ? this.currentCompany.meta : [];
                } else {
                    let errors = response.data.errors.message;
                    let message = "";
                    for (let error in errors) {
                        message = errors[error] + "\n";
                    }
                    this.$notify({
                        title: "Error",
                        message: message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleSubmit() {
            var companyId = this.currentCompany.aloware_id
            if(this.currentCompany.aloware_id == '0') {
                companyId = 'simpid-' + this.currentCompany.id
            }
            this.axios
                .put('/api/company/' + companyId, this.currentCompany)
                .then(response => {
                    if (response.data.success == true) {
                        this.$notify({
                            title: "Success",
                            message: "Company has been successfully Updated",
                            type: "success"
                        });
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        loadCompanyCarDataSources(query) {
            this.axios
                .get("/api/company_car_data_source", {
                    params: {
                        page: 1,
                        page_size: 9999999999,
                        q: query
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.company_car_data_sources = response.data.data;
                    }
                });
        },
        handleCompanyCarDataSourcesChange(selectedDataSourceID) {
            this.company_car_data_sources.forEach((e) => {
                if(selectedDataSourceID == e.id) {
                    this.currentDataSource = e;
                }
            });
        },
        handleCompanyCarDatasourceFormSuccess() {

        },
        handleCompanyMetaFormSuccess() {
            this.getLatestCompanyData();
        },
        handleAddProvider() {
            this.selectedDataSource = {
                id: '',
                name: '',
                email_address: '',
                phone_number: '',
                directory: '',
                data_type: 1,
                dealership_column_header: '',
            };

            this.companyCarDatasourceFormVisible = true;
        },
        handleUpdateProvider(dataSource) {
            this.selectedDataSource = dataSource;
            this.companyCarDatasourceFormVisible = true;
        },
        addMeta() {
            this.selectedMeta = {key:'',value:''};
            this.companyMetaFormVisible = true;
        },
        updateMeta(meta) {
            this.selectedMeta = meta;
            this.companyMetaFormVisible = true;
        },
    },
     watch: {
        company: function(val, oldVal) {
            this.currentCompany = this.company
            this.getLatestCompanyData();
        },
     }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
