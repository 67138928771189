<template>
    <div>
        <el-card
            class="box-card"
            :key="resetKey+1"
        >
            <div slot="header" class="clearfix">
                <span>Facebook Pages ({{ fbPageCount }})</span>
                <el-button
                    style="float: right; padding: 3px 0; color: red;"
                    type="text"
                    @click="handleReauthenticate"
                    size="large"
                >
                    Reauthenticate
                </el-button>
                <fb-user-info
                    v-if="fbUserInfo"
                    :user="fbUserInfo"
                ></fb-user-info>
            </div>

            <el-form
                ref="form"
                :model="subsForm"
                v-if="fbPages.length > 0 && permissionsGood"
                :inline="true"
            >
                <el-row>
                    <el-col :span="14">
                        <div
                            style="display: flex;align-content: center; justify-content: center;"
                            v-loading="true"
                            v-if="loadingButton"
                        ></div>
                        <el-form-item
                            v-if="!loadingButton"
                        >
                            <el-select
                                v-model="subsForm.page"
                                placeholder="Select FB Page"
                                @change="checkPageInstalledApps"
                                :disabled="disablePageIdSelect"
                                filterable
                                value-key="id"
                                style="width: 130%"
                            >
                                <el-option
                                    v-for="page in fbPages"
                                    :key="page.id"
                                    :label="`${page.name} - ${page.id}`"
                                    :option="page"
                                    :value="page"
                                    value-key="id"
                                    :title="page.name"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-row
                            v-if="subsForm.page && subsForm.page.hasOwnProperty('id') && subsForm.page.id && !loadingButton"
                        >
                            <a
                                :href="`https://www.facebook.com/${subsForm.page.id}`"
                                target="_blank"
                                style="font-size: 13px !important;"
                            >
                                 {{ subsForm.page.name }} - {{ subsForm.page.id }}
                            </a>
                            <div style="word-wrap: break-word">
                                <small>{{ pagePermissions(subsForm.page.tasks) }}</small>
                            </div>
                        </el-row>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item>
                            <el-row
                                v-if="subsForm.page && subsForm.page.hasOwnProperty('id') && subsForm.page.id"
                                type="flex"
                                justify="end"
                            >
                                <el-button
                                    v-if="associatedPage && (associatedPage.page_id === subsForm.page.id) && pageAuthorized"
                                    :type="loadingButton ? '' : 'danger'"
                                    @click="subscribeAppToWebhook('delete')"
                                    :loading="loadingButton"
                                >{{ loadingButton ? 'loading...' : 'Dissociate from SimpSocial' }}</el-button>
                                <el-button
                                    v-if="!pageAuthorized"
                                    :type="loadingButton ? '' : 'success'"
                                    @click="subscribeAppToWebhook('post')"
                                    :loading="loadingButton"
                                >{{ loadingButton ? 'loading...' : 'Associate with SimpSocial' }}</el-button>
                                <el-button
                                    v-if="showForceAssociateBtn"
                                    v-show="!loadingButton"
                                    :type="'warning'"
                                    @click="handleInsistPageAssoc"
                                    :loading="loadingButton"
                                >{{ loadingButton ? 'loading...' : 'Insist Page Association' }}</el-button>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-card
                v-if="associatedPage && (associatedPage.page_id === subsForm.page.id) && pageAuthorized"
                style="margin-top: 1rem;"
                :header="'Registered FB Page/s'"
            >
                <el-table
                    :data="currentAssociated"
                    :key="resetKey+2"
                >
<!--                    <el-table-column prop="id" :label="`Push/Pull Leads`" align="center" width="95">-->
<!--                        <template v-slot="scope">-->
<!--                            <span-->
<!--                                :class="scope.row.subscribed ? 'text-success' : 'text-danger'"-->
<!--                            >{{ scope.row.subscribed ? 'Yes' : 'No' }}</span>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column prop="id" label="FB Page Link" align="left">
                        <template v-slot="scope">
                            <a :href="`https://www.facebook.com/${scope.row.page_id}`">
                                {{ scope.row.name || 'FB Page'}}
                            </a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="FB Page ID" align="left">
                        <template v-slot="scope">
                            {{ scope.row.page_id }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>

            <fb-leadgen-synchronization
                v-if="associatedPage && (associatedPage.page_id === subsForm.page.id) && pageAuthorized"
                :registeredPages="currentAssociated"
                :api-endpoint="apiEndpoint"
                :key="resetKey+3"
            ></fb-leadgen-synchronization>

            <fb-lead-intake-by-fb-forms
                v-if="associatedPage && (associatedPage.page_id === subsForm.page.id) && pageAuthorized"
                :registered-pages="currentAssociated"
                :endpoint="apiEndpoint"
                :key="resetKey+4"
            />

            <el-alert
                v-if="!fbPages && !permissionsGood"
                title="Something went wrong"
                type="error"
                :closable="false"
            >
                We are unable to get data from your page. Please grant all requested permissions.
            </el-alert>

<!--            <fb-user-info-->
<!--                v-if="associatedPage && pageAuthorized"-->
<!--                :user="fbUserInfo"-->
<!--            ></fb-user-info>-->
        </el-card>
    </div>
</template>

<script>
import FbLeadIntakeByFbForms from "./FbLeadIntakeByFbForms"
import FbUserInfo from "./FbUserInfo"
import FbLeadgenSynchronization from "./FbLeadgenSynchronization"
export default {
    name: "SelectFBPage",
    components: {
        FbLeadIntakeByFbForms,
        FbUserInfo,
        FbLeadgenSynchronization,
    },
    props: {
        fbData: {
            type: Object,
            required: true
        },
        fbApiEndpoint: {
            type: String,
            required: true
        },
        associated: {
            type: Array,
            required: false,
        },
        fbUserInfo: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            apiEndpoint: '',
            subsForm : {
                page: {
                    id: ""
                },
                forms: [],
                adId: null,
                leadgenFormId: null
            },
            disablePageIdSelect: false,
            permissionsGood: true,
            fbPages: [],
            pageForms: [],
            fbPageCount: 0,
            leadgenForms: [],
            loadingLeadgenForms: false,
            goodToTest: false,
            passedTest: false,
            pageAuthorized: false,
            loadingButton: false,
            currentAssociated: [],
            storedFbPage: {},
            resetKey: 0,
            associatedPage: {},
            company: {},
            showForceAssociateBtn: false,
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.storedFbPage = JSON.parse(localStorage.getItem('selectedFbPage'))
        this.setFBPages()
    },
    methods: {
        async setFBPages() {
            this.fbPages = this.fbData.data
            this.sortPageList()
            this.apiEndpoint = this.fbApiEndpoint
            this.fbPageCount =  this.fbPages.length
            this.currentAssociated = this.associated
            this.associatedPage = this.currentAssociated.find(assoc => assoc.subscribed === 1)

            if(this.storedFbPage) {
                this.subsForm.page.id = this.storedFbPage.page_id || this.storedFbPage.id
                this.subsForm.page.name = this.storedFbPage.name
            }

            await this.selectRegisteredFBPage()
                .then( () => {
                    this.checkCurrentAssociated()
                })
        },
        async checkPageInstalledApps() {
            this.resetKey = ++this.resetKey
            if(this.subsForm.page && this.subsForm.page.hasOwnProperty('id') && this.subsForm.page.id) {
                this.loadingButton = true
                const url = `${this.apiEndpoint}/page-installed-apps?page_id=${this.subsForm.page.id}&access_token=${this.subsForm.page.access_token}&type=get`
                await this.axios.get(url)
                    .then(res => {
                        if(typeof res.data === 'string' && res.data.includes('error')) {
                            this.$notify({
                                type: "error",
                                message: res.data
                            })
                            this.loadingButton = false
                            return this.permissionsGood = false
                        }
                        const apps = res.data.data.apps.data
                        this.pageForms = this.sortItems(res.data.data.forms.data)
                        this.subsForm.forms = res.data.data.selected

                        let appRegistered = false
                        apps.forEach(app => {
                            if(
                                app.name.toLowerCase() === 'simpsocial' ||
                                app.name.toLowerCase().includes('simpsocial')
                            ) {
                                appRegistered = true

                                this.pageAuthorized = app.subscribed_fields.includes('leadgen')
                            }
                        })

                        this.currentAssociated = res.data.data.associated
                        this.associatedPage = this.currentAssociated.find(assoc => assoc.subscribed === 1 && assoc.page_id === this.subsForm.page.id)

                        if(!appRegistered) {
                            this.pageAuthorized = appRegistered
                        }

                        if(!this.associatedPage && this.pageAuthorized) {
                            this.showForceAssociateBtn = true
                        }

                        localStorage.removeItem('selectedFbPage')
                        localStorage.setItem(
                            'selectedFbPage',
                            JSON.stringify(this.subsForm.page)
                        )
                        this.loadingButton = false
                        this.resetKey = ++this.resetKey
                    })
                    .catch(err => {
                        console.log('errCheckPageInstalledApps', err)
                        this.loadingButton = false
                        return this.$notify({
                            type: 'error',
                            message: 'Something went wrong, please contact support'
                        })
                    })

            }
        },
        async subscribeAppToWebhook(type) {
            if(this.subsForm.page.id) {
                let url = `${this.apiEndpoint}/page-installed-apps?page_id=${this.subsForm.page.id}&access_token=${this.subsForm.page.access_token}&type=${type}`
                url += `&name=${this.subsForm.page.name}`
                await this.axios.get(url)
                    .then(res => {
                        if(typeof res.data === 'string' && res.data.includes('error')) {
                            this.$notify({
                                type: "error",
                                message: res.data
                            })
                            return this.permissionsGood = false
                        }
                        this.currentAssociated = []
                        this.currentAssociated = res.data.data.associated
                        if(type === 'delete' && res.data.data.apps.success) {
                            this.pageAuthorized = false
                            return this.$notify({
                                type: 'warning',
                                message: 'Dissociated!'
                            })
                        }

                        this.pageAuthorized = true
                        return this.$notify({
                            type: 'success',
                            message: 'Authorization successful!'
                        })

                    })
                    .catch(err => {
                        console.log('errSubscribeAppToWebhook', err)
                        return this.$notify({
                            type: 'error',
                            message: 'Something went wrong, please contact support'
                        })
                    })

            }
        },
        closeAddSubscription() {
            if(this.fbPages.length < 1 || !this.permissionsGood) {
                this.$emit('error-permission')
            }
            return this.$emit('close-add-subscription')
        },

        sortPageList() {
            if(this.fbPages && Array.isArray(this.fbPages) && this.fbPages.length) {
                this.fbPages.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
            }
        },

        async updatePageForm() {
            const params = {
                'page_id': this.subsForm.page.id,
                forms: this.subsForm.forms
            }
            await this.axios.post(
                `${this.apiEndpoint}/leadgen-forms`,
                params
            )
                .then(res => {
                    this.subsForm.forms = res.data.data
                    return this.$notify({
                        type: 'success',
                        title: 'Forms has been updated',
                    })
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: err.response.data.message,
                    })
                })
        },

        sortItems(items) {
            return items.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
        },

        handleReauthenticate() {
            this.$emit('reauthenticate')
        },

        pagePermissions(arr) {
            if(Array.isArray(arr) && arr.length) {
                const str = arr.join(', ')
                const lastIndex = str.lastIndexOf(',')
                const replacement = ' &'
                return str.substring(0, lastIndex) + replacement + str.substring(lastIndex + 1)
            }
            return ''
        },

        async selectRegisteredFBPage() {
            if(this.currentAssociated.length && this.subsForm.page && this.subsForm.page.hasOwnProperty('id') && this.subsForm.page.id) {
                this.subsForm.page = this.fbPages.find(fb => this.subsForm.page.id === fb.id)
            } else {
                this.subsForm.page = {}
            }
            if(this.subsForm.page && this.subsForm.page.hasOwnProperty('id') && this.subsForm.page.id) {
                await this.checkPageInstalledApps()
            }
            return true
        },

        checkCurrentAssociated() {
            if(this.subsForm.page && this.subsForm.page.hasOwnProperty('id') && this.subsForm.page.id) {
                const same_comp = this.currentAssociated.find(assoc => assoc.page_id === this.subsForm.page.id)
                if (!same_comp) {
                    this.subsForm.page = this.fbPages.find(page => page.name.includes(this.company.name))
                    return this.checkPageInstalledApps()
                }
            }
        },

        async handleInsistPageAssoc() {
            this.$confirm(
                `The associate button will be shown to override the current setup.`,
                'Action Confirmation',
                {
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'CANCEL',
                    type: 'warning',
                }
            )
                .then(res => {
                    this.pageAuthorized = false
                    this.showForceAssociateBtn = false
                })
                .catch(err => {
                    return this.$message({
                        type: 'info',
                        message: 'Action has been canceled.'
                    })
                })
        }
    }
}
</script>
