import { render, staticRenderFns } from "./FbLeadgenTable.vue?vue&type=template&id=0deb0430&"
import script from "./FbLeadgenTable.vue?vue&type=script&lang=js&"
export * from "./FbLeadgenTable.vue?vue&type=script&lang=js&"
import style0 from "./FbLeadgenTable.vue?vue&type=style&index=0&id=0deb0430&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports