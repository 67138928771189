<template>
    <div>
        <el-row v-if="showDealershipSelector" style="width: 100%">
            <el-col :span="12">
                &nbsp;
            </el-col>
            <el-col :span="12" style="text-align: right">
                <company-filter
                    v-if="showDealershipSelector"
                    style="margin-top: 1rem;"
                    @change="handleFilterChange"
                ></company-filter>
            </el-col>
        </el-row>
        <el-container style="height: 100vh">
            <div v-if="isAuthenticated===false || !isMessengerEnabled" style="display: inline-flex;width: 100%;text-align: center;">
                <el-link
                    class="btn-authorize"
                    type="primary"
                    :href="'/messenger/authorize_app?company_id=' + user.company_id + '&redirect=messenger'"
                    target="_parent"><i class="el-icon-top-right"></i> Authorize Simpsocial to manage your page
                </el-link>
            </div>
            <template v-else-if="isAuthenticated && isPageReady">
                <el-aside width="360px" class="contact-list">
                    <div class="contact-list-header">
                        <img src="images/fb-messenger-logo.png" alt='messenger logo' />
                        <div class="heading">Chats</div>
                    </div>
                    <div v-if="conversations.items.length <= 0">
                        No Contact found. Please ensure that the page is properly connected
                    </div>
                    <div v-else class="contact-list-body" v-on:scroll.passive="handleContactListScroll" ref="conversationHistory">
                        <div v-for="convo in conversations.items" :key="convo.id" v-on:click="handleRecipientChange(convo)">
                            <el-card :class="['convo-' + convo.id, { active : message.recipient_id === convo.id, 'active-recipient' : message.recipient_id === convo.id }]" :key="convo.id" shadow="none">
                                <img style="height: 56px; width: 56px; border-radius: 50%" :src="convo.profile_pic" alt='profile picture'>
                                <div class="msg-info">
                                    <div class="msg-user-details">
                                        <div class="msg-name">{{ convo.first_name + ' ' + convo.last_name }}</div>
                                        <div class="msg-details">
                                            <div class="short-message ellipsis-1">{{ formatShortMessage(convo) }}&nbsp;·&nbsp;</div>
                                            <div class="convo-time">{{ convo.messages.items[convo.messages.items.length - 1].time | timestampFormatDateTime }}</div></div>
                                    </div>
                                    <!--                            <div class="msg-status" :class=" convo.unread_count > 0 ? '' : 'invisible' "><span></span></div>-->
                                </div>
                                <el-badge
                                    v-if="convo.unread_count > 0"
                                    :value="convo.unread_count"
                                    :max="99" class="item"
                                >
                                </el-badge>
                            </el-card>
                        </div>
                    </div>
                </el-aside>
                <el-main class="message-list">
                    <div v-if="conversations.items.length <= 0">
                        No Conversation found
                    </div>
                    <el-form v-else ref="form" label-width="0">
                        <div class="message-history" v-if="messages" v-on:scroll.passive="handleMessageListScroll" ref="messageHistory">
                            <div
                                v-for="msg in messages.items"
                                :key="msg.id">
                                <div v-if="msg.time" class="msg-row" :class="msg.direction===0 ? 'right' : 'left'">
                                    <div class="msg-item" :class="msg.bg_transparent===1 ? 'bg-transparent' : ''">
                                        <div v-html="msg.body"></div>
                                    </div><br>
                                    <small>{{ msg.time | timestampFormatDateTime }}<template  v-if='!msg.is_attachment'> | <el-link @click="handleTranslate(msg,  'en')" >Translate (en)</el-link></template></small>
                                </div>
                            </div>
                        </div>
                        <div class="action-buttons">
                            <el-tooltip class="item" effect="dark" content="Add Variables" placement="top">
                                <el-popover
                                    placement="top"
                                    width="200"
                                    trigger="click"
                                    @show="handleVariableShown">
                                    <el-select
                                        v-model="selectedVariable"
                                        :automatic-dropdown="true"
                                        ref="variableSelector"
                                        @change="handleVariableSelectorChange">
                                        <el-option
                                            v-for="item in variableOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            <label for="" style="width: 100%">{{item.value}}</label>
                                            <small>{{item.label}}</small>
                                        </el-option>
                                    </el-select>
                                    <el-link slot="reference" title="Add Variable" class="btn-grad btn-grad-variable" type="secondary"><i class="el-icon-plus"></i><span class="btn-name">Variable</span></el-link>
                                </el-popover>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Templates" placement="top">
                                <el-link class="btn-grad btn-grad-template" type="secondary" @click="templatesVisible = true"><i class="el-icon-document"></i><span class="btn-name">Templates</span></el-link>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Inventory" placement="top">
                                <el-link title="Inventory" class="btn-grad btn-grad-inventory" type="secondary" @click="companyInventoryVisible = true"><i class="el-icon-truck"></i><span class="btn-name">Inventory</span></el-link>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Send Credit App" placement="top">
                                <el-link title="Send Credit App" class="btn-grad btn-grad-credit-app" type="secondary" @click="handleCreditApplicationSendLink"><i class="el-icon-edit-outline"></i><span class="btn-name">Credit App</span></el-link>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Send Media File" placement="top">
                                <el-link title="Send Media File" class="btn-grad btn-grad-media" type="secondary" @click="mediaFileVisible = true"><i class="el-icon-picture"></i><span class="btn-name">Media File</span></el-link>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Send Giphy" placement="top">
                                <el-link title="Send Giphy" class="btn-grad btn-grad-giphy" type="secondary" @click="giphySettings.visible = true"><img class="btn-icon" src="images/giphy.png" alt
                                /><span class="btn-name">Giphy</span></el-link>
                            </el-tooltip>
                            <el-tooltip v-if="this.selectedContact.aloware_contact_id==''" class="item" effect="dark" content="Add Lead" placement="top">
                                <el-link
                                    title="Add Lead"
                                    class="btn-grad btn-grad-lead"
                                    type="secondary"
                                    @click="addAsContactVisible = true"><i class="el-icon-notebook-2"></i><span class="btn-name">Lead</span></el-link>
                            </el-tooltip>
                            <el-tooltip v-else class="item" effect="dark" content="View Contact" placement="top">
                                <el-link
                                    title="View Contact"
                                    class="btn-grad btn-grad-lead"
                                    type="primary"
                                    :href="'https://app.aloware.com/contacts/'+this.selectedContact.aloware_contact_id"
                                    target="_parent"><i class="el-icon-notebook-2"></i><span class="btn-name">Contact</span>
                                </el-link>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Send My Number" placement="top">
                                <el-link title="Send My number" class="btn-grad btn-grad-support" type="secondary" @click="handleSendSupport"><i class="el-icon-user-solid"></i><span class="btn-name">My number</span></el-link>
                            </el-tooltip>
                        </div>
                        <div class="message-form" id="messageform">
                            <el-link class="translate-message-button" type="primary" @click="handleTranslate"> Translate ({{selectedTransLang}})</el-link>
                            <div ref="textareaContainer" class="textarea-container">
                                <input type="hidden" v-model="message.recipient_id">
                                <div class="el-textarea">
                                    <textarea ref="messageTextarea" autocomplete="off" v-model="message.message"  placeholder="Aa" class="el-textarea__inner" @click="handleSenderAction" v-on:keyup="recalculateMessageBoxHeight"></textarea>
                                </div>
                            </div>
                            <el-link class="send-message-button" type="primary" @click="handleSubmit"><i class="el-icon-s-promotion"></i> Send</el-link>
                        </div>
                        <footer class="text-center">
                            <a href="https://simpsocial.com/privacy-policy" target="_blank">Privacy Policy</a> | <a href="https://simpsocial.com/terms-and-conditions/" target="_blank">Terms & Conditions</a>
                        </footer>
                    </el-form>
                </el-main>
            </template>
            <template v-else>
                <div style="display: flex;width: 100%;height: 100vh;align-items: center;">
                    <div class="text-center" style='margin: 0 auto;'>
                        <div class="cogs-container">
                            <i class="fas fa-cog fa-spin custom-cog"></i>
                            <i class="fas fa-cog fa-spin spin-reverse custom-cog-sm"></i>
                        </div>
                        <p class="loading-text">Loading Facebook Messenger...</p>
                    </div>
                </div>
            </template>
            <el-dialog title="Send a Car" :visible.sync="companyInventoryVisible">
                <el-row >
                    <el-col :span="24">
                        <el-input
                            v-model="search"
                            size="mini"
                            placeholder="Search for make, model, stock #, vin"
                        />
                    </el-col>
                    <el-col :span="24">&nbsp;</el-col>
                    <el-col :span="24">
                        Price range:
                        <el-tag size="mini">$ {{pricerange[0]}}</el-tag>&nbsp;-&nbsp;
                        <el-tag size="mini">$ {{pricerange[1]}}</el-tag>
                        <el-slider
                            v-model="pricerange"
                            range
                            :max="price_max"
                            :step="1000"
                            label
                            @change="handlePricerangeChange"
                            style="padding: 0 10px;"
                        ></el-slider>
                    </el-col>
                </el-row>
                <div class="cars-listing" v-for="car in cars" v-bind:key="car.id">
                    <div class="car-heading">
                        <el-button
                            size="large"
                            type="primary"
                            style="float: right"
                            @click="handleSendLink(car)"
                        >
                            Send Link
                        </el-button>
                    </div>
                    <div class="car-image">
                        <img
                            v-if="typeof car.galleries[0] !== 'undefined'"
                            :src="car.galleries[0].url"
                            alt="Car Image"
                            style="max-width: 100%;"
                        />
                    </div>
                    <div class="car-details">
                        <h3>Vin #: {{ car.vin}}</h3>
                        <strong>Description:</strong><br>
                        <p class="ellipsis-2">{{ car.description }}</p><br>
                        <strong>Price:</strong><br>
                        {{ car.price }}
                    </div>
                </div>
                <el-pagination
                    background
                    @current-change="handlePageChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="prev, pager, next"
                    :total="totalRecords"
                ></el-pagination>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="companyInventoryVisible = false">Close</el-button>
                </span>
            </el-dialog>
            <el-dialog title="Send Media" :visible.sync="mediaFileVisible" @close="closeMediaFileDialog">
                <el-row >
                    <el-col :span="24">
                        <p>Upload the media file:</p>
                        <el-upload
                            class="upload-demo"
                            drag
                            thumbnail-mode
                            :action="companyApiBaseUrl + '/aws_fileupload?token=' + this.$store.state.token"
                            :on-success="handleSuccessUpload"
                            :auto-upload="false"
                            :before-upload="handleBeforeUpload"
                            :limit="1"
                            :on-exceed="handleExceedUpload"
                            ref="uploadMediaFile"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                                Drop file here or
                                <em>click to upload</em>
                            </div>
                            <div class="el-upload__tip" slot="tip">Maximum file size(25MB)</div>
                        </el-upload>
                    </el-col>
                    <el-col :span="24">&nbsp;</el-col>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="mediaFileVisible = false">Close</el-button>
                    <el-button type="primary" style="margin-left: 10px;" @click="submitUploadMediaFile">Send</el-button>
                </span>
            </el-dialog>
            <el-dialog class="giphy-dialog" :visible.sync="giphySettings.visible" width="30%">
                <div class="giphy-header" slot="title">
                    <div class="giphy-text">Send</div>
                    <object height="18.5" type="image/svg+xml" data="images/Giphy-logo.svg" class="giphy-logo">
                        Giphy Logo <!-- fallback image in CSS -->
                    </object>
                </div>
                <el-row >
                    <el-col :span="24" style="display: flex">
                        <input class="search-bar" type="text"
                            @keyup="searchGiphy($event)"
                            size="mini"
                            placeholder="Search"
                        />
                    </el-col>
                    <el-col :span="24">&nbsp;</el-col>
                </el-row>
                <div class="giphy-listing">
                    <div class="gif-container" v-for="gif in giphySettings.data" :key="gif.id" @click="sendGiphy(gif.images.original.url)" >
                        <img
                            :src="gif.images.downsized.url"
                            :alt="gif.title"
                        />
                    </div>
                </div>
                <el-pagination
                    background
                    small
                    @current-change="handleGifPageChange"
                    :current-page.sync="giphySettings.page"
                    :page-size="giphySettings.limit"
                    layout="prev, pager, next"
                    :total="giphySettings.total"
                ></el-pagination>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="giphySettings.visible = false">Close</el-button>
                </span>
            </el-dialog>
            <MessengerTemplatesDialog
                :company="user.company"
                :visible.sync="templatesVisible"
                v-on:onSelectTemplate="handleOnTemplateSelect" />

            <MessengerAddAsContactDialog
                :contact.sync="selectedContact"
                :visible.sync="addAsContactVisible" />
        </el-container>
    </div>
</template>

<script>
import store from "../../store";
import MessengerTemplatesDialog from './../dialogs/MessengerTemplatesDialog';
import MessengerAddAsContactDialog from './../dialogs/MessengerAddAsContactDialog';
import CompanyFilter from "../admin/components/CompanyFilter"

export default {
    name: 'company-messenger',
    components: {
        MessengerTemplatesDialog,
        MessengerAddAsContactDialog,
        CompanyFilter,
    },
    props: {
        showDealershipSelector: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            vh: window.innerHeight * 0.01,
            uploading: false,
            errors: {},
            user: {},
            company: {},
            conversations: {},
            isProgress: false,
            messages: null,
            message: {
                recipient_id: 0,
                conversation_id: 0,
                message: '',
                message_type: 'text',
            },
            companyInventoryVisible: false,
            cars: [],
            search: "",
            pricerange: [0, 50000],
            price_min: 0,
            price_max: 50000,
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            isPageReady: false,
            mediaFileVisible: false,
            giphySettings: {
                data: [],
                page: 1,
                offset: 0,
                limit: 10,
                total: 0,
                visible: false,
                loading: ''
            },
            templatesVisible: false,
            addAsContactVisible: false,
            selectedContact: {
                first_name: '',
                last_name: '',
                phone_number: '',
                messenger_id: '',
                aloware_contact_id: '',
            },
            loadConversationsRetry: 0,
            selectedConvo: {},
            moreActionOpened: false,
            isAuthenticated: '',
            auth: {
                auth_url: '',
            },
            variableOptions: [{
                'label': 'to include contact’s first name',
                'value': '[FirstName]',
            },{
                'label': 'to include contact’s last name',
                'value': '[LastName]',
            },{
                'label': 'to include contact’s full name',
                'value': '[FullName]'
            },{
                'label': 'to include contact’s company name',
                'value': '[AccountName]'
            },{
                'label': 'to include contact’s agent name',
                'value': '[AgentName]'
            }],
            selectedVariable: '',
            loadingMoreConvo: '',
            loadingMoreMessage: '',
            loadingSenderAction: '',
            senderAction: '',
            translationLanguages: {},
            selectedTransLang: 'es',
            nextpageLink: '',
            pageFreshLoaded: true,
            convoCounter: 0,
            convoToLoad: 20,
            isMessengerEnabled: false
        };
    },
    computed: {},
    // beforeMount: function() {
    //     this.loadConversations()
    //     this.loadCars()
    // },
    mounted() {
        document.documentElement.style.setProperty('--vh', `${this.vh}px`)
        this.user = JSON.parse(store.state.currentUser)
        this.company = JSON.parse(store.state.currentCompany)
        this.getLatestCompanyData()
    },
    methods: {
        getLatestCompanyData() {
            this.axios
            .get('/api/company/' + this.company.aloware_id)
            .then(response => {
                if (response.data.success == true) {
                    this.company = response.data.data;
                    this.isMessengerEnabled = response.data.data.facebook_messenger_integration !== null ? response.data.data.facebook_messenger_integration.status == 1 : false

                    if( this.isMessengerEnabled ) {
                        this.authenticate()
                    }
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response)
            });
        },
        recalculateMessageBoxHeight(event) {
            let _messageTextareaHeight = parseInt(this.$refs.messageTextarea.clientHeight)

            if(this.$refs.messageTextarea.value) {
                this.handleSenderAction('typing_on')
            }
            else {
                this.handleSenderAction('typing_off')
            }

            let numberOfLineBreaks = (this.$refs.messageTextarea.value.match(/\n/g)||[]).length
            if (event.shiftKey === true && event.key === "Enter" && this.$refs.messageTextarea.clientHeight <= 108) {
                this.$refs.messageTextarea.style.height = 28 + (20 * numberOfLineBreaks) + 'px'
                this.$refs.textareaContainer.style.height = 38 + (20 * numberOfLineBreaks) + 'px'
            }
            else if (event.key === "Enter") {
                this.handleSenderAction('typing_off')
                this.handleSubmit()
            }
            else {
                this.$refs.messageTextarea.style.height = 28 + (20 * numberOfLineBreaks) + 'px'
                this.$refs.textareaContainer.style.height = 38 + (20 * numberOfLineBreaks) + 'px'
            }
        },
        loadGiphys() {
            var scope = this
            scope.giphySettings.data = []
            this.giphy.trending({ offset: scope.giphySettings.offset, limit: scope.giphySettings.limit })
                .then(response => {
                    scope.giphySettings.total = response.pagination.total_count
                    scope.giphySettings.data = response.data // .chunckArray(10)
                })
        },
        searchGiphys(term) {
            var scope = this
            scope.giphySettings.data = []
            this.giphy.search(term,{ offset: scope.giphySettings.offset, limit: scope.giphySettings.limit })
                .then(response => {
                    scope.giphySettings.total = response.pagination.total_count
                    scope.giphySettings.data = response.data // .chunckArray(10)

                    //console.log('searchGiphys', response)
                    // console.log('giphySettings.data', response)
                })
        },
        loadMoreConvo() {
            let scope = this

            if(scope.axiosProcess) return

            if(!scope.conversations.paging.next)  return false

            scope.loadingMoreConvo = this.axios.get(this.companyApiBaseUrl + "/messenger/loadMoreConvo", {
                params: {
                    next: scope.conversations.paging.next
                }
            })
                .then(response => {
                    var data = response.data
                    if(data.success) {
                        for (let i=0; i<data.data.items.length; i++ ) {
                            scope.conversations.items.push(data.data.items[i])
                        }
                        scope.conversations.paging = data.data.paging
                        scope.axiosProcess = ''
                    }
                    scope.loadingMoreConvo = ''
                })
                .catch((error) => {
                    scope.loadingMoreConvo = ''
                })
        },
        loadMoreConvoByPageUrl() {
            let scope = this

            if(scope.axiosProcess) return

            if(this.convoCounter >= this.convoToLoad) {
                this.convoCounter = 0
                return;
            } else {
                this.convoCounter++
            }

            if(this.nextpageLink == '') {
                return
            }

            //if(!scope.conversations.paging.next)  return false

            scope.loadingMoreConvo = this.axios.get(this.companyApiBaseUrl + "/messenger/getConversationsByPageNextUrl", {
                params: {
                    page_next_url: this.nextpageLink
                }
            })
                .then(response => {
                    var data = response.data
                    if(data.success) {
                        for (let i=0; i<data.data.items.length; i++ ) {
                            scope.conversations.items.push(data.data.items[i])
                        }
                        scope.conversations.paging = data.data.paging
                        scope.axiosProcess = ''

                        if(scope.conversations.paging.next !== '') {
                            this.nextpageLink = scope.conversations.paging.next
                            this.loadMoreConvoByPageUrl()
                        } else {
                            this.nextpageLink = ''
                        }
                    }
                    scope.loadingMoreConvo = ''
                })
                .catch((error) => {
                    scope.loadingMoreConvo = ''
                })
        },
        loadMoreMessage() {
            let scope = this
            let messages = scope.messages

            if(scope.loadingMoreMessage) return

            if(!messages.paging.next)  return false

            scope.loadingMoreMessage = this.axios.get(this.companyApiBaseUrl + "/messenger/loadMoreMessage", {
                params: {
                    next: messages.paging.next
                }
            })
                .then(response => {
                    var data = response.data
                    if(data.success) {
                        // scope.conversations = response.data.data

                        for (var index in scope.conversations.items) {
                            //console.log('conversations[index]', scope.conversations.items[index])
                            //console.log('conversations[index].id', scope.conversations.items[index].id)
                            //console.log('data.data.from', data.data.from)
                            if (scope.conversations.items[index].id === data.data.from) {
                                //console.log('loadMoreMessage', scope.conversations.items[index].messages.items)
                                //console.log('loadMoreMessage', data.data.messages.items)
                                for(var key in data.data.messages.items) {
                                    scope.conversations.items[index].messages.items.unshift(data.data.messages.items[key])
                                }
                                scope.conversations.items[index].messages.paging = data.data.messages.paging
                            }
                        }

                        scope.$refs.messageHistory.scrollTop = scope.$refs.messageHistory.clientHeight
                        scope.loadingMoreMessage = ''
                        // scope.messages = scope.conversations[0].messages
                    }
                })
                .catch((error) => {
                    scope.loadingMoreMessage = ''
                })
        },
        loadConversations: async function () {
            //console.log(this.companyApiBaseUrl + "/messenger/getConversations");
            let scope = this
            await this.axios.get(this.companyApiBaseUrl + "/messenger/getConversations")
                .then(response => {
                    if(response.data.success) {
                        scope.conversations = response.data.data
                        scope.selectedConvo = scope.conversations.items[0]
                        scope.messages = scope.conversations.items.length > 0 ? scope.conversations.items[0].messages : []
                        // scope.$set(scope.conversations[0], 'previousScrollHeightMinusTop', 0)
                        scope.isPageReady = true
                        scope.listen()

                        this.convoCounter++

                        if(scope.conversations.paging.next) {
                            this.nextpageLink = scope.conversations.paging.next
                            this.loadMoreConvoByPageUrl();
                        }

                        if( scope.conversations.items.length > 0 ) {
                            this.selectedContact.first_name = scope.conversations.items[0].first_name
                            this.selectedContact.last_name = scope.conversations.items[0].last_name
                            this.selectedContact.messenger_id = scope.conversations.items[0].id
                            this.selectedContact.aloware_contact_id = ''
                            this.message.conversation_id = scope.conversations.items[0].conversation_id;
                            this.message.recipient_id = scope.conversations.items[0].id;

                            this.getContact();
                        }


                        setTimeout(function() {
                            scope.$refs.conversationHistory.scrollTop = 0;
                            scope.$refs.messageHistory.scrollTop = scope.$refs.messageHistory.scrollHeight;
                        }, 3000)
                    }
                })
                .catch((error) => {
                    // retry upto 5 else log error
                    if(scope.loadConversationsRetry < 5) {
                        scope.loadConversationsRetry++
                        scope.loadConversations()
                        return
                    }

                    this.$notify({
                        title: "Error",
                        message: error,
                        type: "error"
                    });
                })
        },
        listen: function() {
            let scope = this
            Echo.private(`fb-message-received-${this.company.id}`).listen('FacebookMessenger', (payload) => {
                if(payload) {
                    console.log('payload', payload)
                    let isNew = true
                    for (var index in scope.conversations.items) {
                        if (scope.conversations.items[index].id === payload.data.fb_contact_id) {
                            isNew = false
                            scope.conversations.items[index].snippet = payload.data.snippet
                            scope.conversations.items[index].unread_count = parseInt(scope.conversations.items[index].unread_count) + 1
                            scope.conversations.items[index].messages.items.push(payload.data.messages.items[0])
                            // break;
                        }
                    }

                    if( isNew ) {
                        scope.conversations.items.unshift(payload.data)
                    }
                }
            });
        },
        handleSubmit() {
            //console.log('submit is called!!!')
            this.postMessage(this.message)
        },
        handleTranslate: async function(msg, lang) {
            let scope = this

            if(typeof this.message.message !== "undefined" && this.message.message == '' && !msg.body) return

            let data = {
                'text': (!msg.body) ? this.message.message : msg.body,
                'lang': (!lang) ? this.selectedTransLang : lang
            }

            this.axios
                .post(
                    this.companyApiBaseUrl + "/google/translateText",
                    data
                )
                .then(response => {
                    if(response.data.success) {
                        if(msg.body) msg.body = response.data.data.translation.translatedText
                        else this.message.message = response.data.data.translation.translatedText
                    }
                    if(!response.data.success) {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            message: response.data.message,
                            duration: 20000,
                        })
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        replaceMessageVariables(message) {
            var variables = {
                '[FirstName]': this.selectedConvo.first_name,
                '[LastName]': this.selectedConvo.last_name,
                '[FullName]': this.selectedConvo.first_name + ' ' + this.selectedConvo.last_name,
                '[AccountName]': this.company.name,
                '[AgentName]': this.user.name
            }

            var patternStr = ''
            for(var key in variables) {
                key = key.replace('[','\\[')
                key = key.replace(']','\\]')
                patternStr += '(' + key + ')|'
            }

            patternStr = '(?:'+ patternStr.slice(0, -1) + ')'
            const pattern = new RegExp(patternStr, "gi")

            return message.replace(pattern, function(matched) {
                return variables[matched]
            })
        },
        postMessage(message) {
            let scope = this

            if(typeof message.message !== "undefined" && message.message == '') return

            if (message.message) message.message = scope.replaceMessageVariables(message.message)

            this.axios
                .post(
                    this.companyApiBaseUrl + "/messenger/submit",
                    message
                )
                .then(response => {
                    if (response.data.success == true) {
                        scope.messages.items.push(response.data.message)
                        scope.message.message = ''
                    } else {
                        this.$notify({
                            title: "Error",
                            message: response.data.message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        loadCars() {
            this.axios
                .get(this.companyApiBaseUrl + "/car", {
                    params: {
                        company_id: this.company.id,
                        page: this.currentPage,
                        q: this.search,
                        price_min: this.pricerange[0],
                        price_max: this.pricerange[1]
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.cars = response.data.data;
                        this.totalRecords = response.data.pagination.total;

                        if (!this.isSearching) {
                            this.pricerange[1] = response.data.max_price_db;
                        }
                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting cars list",
                            type: "error"
                        });
                    }
                });
        },
        currencyFormat(row) {
            return this.$options.filters.currency(row.price);
        },
        handleRecipientChange(convo) {
            var scope = this;

            this.message.recipient_id = convo.id;
            this.message.conversation_id = convo.conversation_id;
            this.selectedConvo = convo;
            this.messages = convo.messages;
            this.message.message = ''

            this.selectedContact.first_name = convo.first_name;
            this.selectedContact.last_name = convo.last_name;
            this.selectedContact.messenger_id = convo.id
            this.selectedContact.aloware_contact_id = ''

            this.getContact();

            this.conversations.items = this.conversations.items.map(msg => {
                let data = msg
                let marking = this.handleMarkAllAsRead(data.id).success
                return data
            });

            setTimeout(function() {
                scope.$refs.messageHistory.scrollTop = scope.$refs.messageHistory.scrollHeight;
            }, 1000)
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadCars();
        },
        handlePricerangeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadCars();
        },
        handleSendSupport() {
            let scope = this
            this.postMessage({
                recipient_id: this.message.recipient_id,
                message_type: 'support-template',
                phone_number: scope.company.representative_phone,
                contact: scope.selectedContact
            });
        },
        handleSendLink(car) {

            this.postMessage({
                recipient_id: this.message.recipient_id,
                message_type: 'car-template',
                car_id: car.id,
                first_name: this.selectedContact.first_name,
                last_name: this.selectedContact.last_name,
            });

            this.companyInventoryVisible = false
        },
        handleContactListScroll: function(e) {
            if(e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight)-100) {
                if(this.convoCounter <=0) {
                    this.convoToLoad = 5
                    this.loadMoreConvoByPageUrl()
                }

            }
        },
        handleMessageListScroll: function(e) {
            this.previousScrollHeightMinusTop = e.target.scrollHeight - e.target.scrollTop
            if (e.target.scrollTop === 0) {
                this.loadMoreMessage()
            }
        },
        sendAttachment(uploded_file) {
            let scope = this
            this.axios
                .post(
                    this.companyApiBaseUrl + "/messenger/sendMessageAttachment",
                    uploded_file
                )
                .then(response => {
                    if (response.data.success == true) {
                        if(response.data.data) {
                            scope.messages.items.push(response.data.data)
                            setTimeout(function() {
                                scope.mediaFileVisible = false
                                scope.$refs.messageHistory.scrollTop = scope.$refs.messageHistory.scrollHeight;
                            }, 1000)
                        }
                    } else {
                        this.$notify({
                            title: "Error",
                            message: response.data.message,
                            type: "error"
                        });
                    }
                    scope.giphySettings.visible = false
                    scope.giphySettings.loading.close()
                })
                .catch(err => {
                    scope.giphySettings.visible = false
                    scope.giphySettings.loading.close()
                    this.$root.handleErrors(err.response);
                });
        },
        handleSuccessUpload(res, file) {
            var scope = this
            // this.uploaded.file = res.url;

            let uploded_file = {
                recipient_id: this.message.recipient_id,
                url: res.url,
                type: file.raw.type
            }

            if(res.success) {
                scope.sendAttachment(uploded_file)
            }
        },
        handleBeforeUpload() {},
        handleOnTemplateSelect() {
            console.log('template selected');
        },
        handleExceedUpload(files, fileList) {
            this.$notify({
                title: "Error",
                message: `Maximum media files to be upload exceeded`,
                type: "error"
            });
        },
        submitUploadMediaFile() {
            this.$refs.uploadMediaFile.submit();
        },
        closeMediaFileDialog() {
            this.$refs.uploadMediaFile.clearFiles()
        },
        formatShortMessage(convo) {
            let shortMessage = ''

            if(convo.messages.items[convo.messages.items.length - 1].direction==0) shortMessage += 'You: '

            if(convo.messages.items[convo.messages.items.length - 1].is_attachment) {
                if(convo.messages.items[convo.messages.items.length - 1].direction==0)
                    shortMessage = 'You sent an attachment.'
                else
                    shortMessage = convo.first_name + ' sent an attachment.'
            }
            else {
                shortMessage += convo.snippet
            }

            return shortMessage
        },
        sendGiphy(url) {
            let scope = this

            scope.giphySettings.loading = this.$loading({
                target: '.giphy-dialog .el-dialog'
            })
            let uploded_file = {
                recipient_id: this.message.recipient_id,
                url: url,
                type: 'image/gif'
            }

            scope.sendAttachment(uploded_file)
        },
        searchGiphy (e) {
            let scope = this
            let timeout = null;
            clearTimeout(timeout);
            // Make a new timeout set to go off in 800ms
            timeout = setTimeout(() => {
                scope.giphySettings.page = 1
                scope.giphySettings.offset = (scope.giphySettings.page - 1) * scope.giphySettings.limit
                if(e.target.value) {
                    scope.searchGiphys(e.target.value)
                } else {
                    scope.loadGiphys()
                }
            }, 800);
        },
        handleGifPageChange(page) {
            let scope = this

            let offset = (page - 1) * scope.giphySettings.limit

            scope.giphySettings.page = page
            scope.giphySettings.offset = offset

            let textbox = document.querySelector('.search-bar')
            console.log('handleGifPageChange page', page, offset)
            if(textbox.value) {
                console.log('handleGifPageChange', scope.giphySettings)
                scope.searchGiphys(textbox.value)
            } else {
                scope.loadGiphys()
            }
        },
        getContact() {
            this.axios
                .get(`${this.companyApiBaseUrl}/messenger/contact/${this.selectedContact.messenger_id}`)
                .then(response => {
                    if (response.data.success == true) {
                        this.selectedContact = response.data.data;
                    } else {

                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        loadTranslationLanguages() {
            let scope = this

            this.axios
                .get(
                    this.companyApiBaseUrl + "/google/getTranslationLanguages",
                )
                .then(response => {
                    // console.log(response.data)
                    // if(response.data.success) {
                    //     this.message.message = response.data.data.translation.translatedText
                    // }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        authenticate() {
            this.axios
                .get(`${this.companyApiBaseUrl}/messenger/auth`)
                .then(response => {
                    if (response.data.success == true) {
                        this.isAuthenticated = true;

                        this.loadTranslationLanguages();
                        this.loadConversations();
                        this.loadGiphys();
                        this.loadCars();
                    } else {
                        this.isAuthenticated = false;
                        this.$emit('messenger-authorized', false)
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        handleCreditApplicationSendLink() {
            this.axios
                .get(`${this.companyApiBaseUrl}/messenger/getCreditApplicationLink?fname=${this.selectedContact.first_name}&lname=${this.selectedContact.last_name}`)
                .then(response => {
                    if (response.data.success == true) {
                        this.message.message = response.data.data;
                        this.postMessage(this.message);
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        handleVariableShown() {
            this.$nextTick(() => this.$refs.variableSelector.focus())
        },
        handleVariableSelectorChange(value, event) {
            this.copyToClipboard(value);

            this.$notify({
                title: "Success",
                message: "Variable has been copied to clipboard",
                type: "success"
            });
            //templateformfooter
            document.getElementById('messageform').click()
        },
        copyToClipboard(val){
            var dummy = document.createElement("input");
            dummy.style.height = '0px';
            dummy.style.width = '1px';
            document.body.appendChild(dummy);

            dummy.setAttribute("id", "dummy_id");
            document.getElementById("dummy_id").value = val;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        },
        handleSenderAction(action) {
            let scope = this
            if(!this.message.recipient_id) return
            if(this.loadingSenderAction) return

            let data = {
                recipient_id: this.message.recipient_id,
                sender_action: 'MARK_SEEN'
            }

            if(typeof action === 'string') {
                data.sender_action = action
            }

            if(!this.senderAction) this.senderAction = data.sender_action
            else {
                if(this.senderAction==data.sender_action) return
                else this.senderAction = data.sender_action
            }

            this.loadingSenderAction = this.axios
                .post(
                    this.companyApiBaseUrl + "/messenger/senderAction",
                    data
                )
                .then(response => {
                    console.log(response.data)
                    scope.loadingSenderAction = ''
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loadingSenderAction = ''
                });
        },
        async handleMarkAllAsRead(recipient) {
            let data = {
                recipient_id: recipient,
                sender_action: 'MARK_SEEN'
            }

            await this.axios.post(this.companyApiBaseUrl + "/messenger/senderAction", data)
                .then(res => {
                    const data = res.data
                    /*if(!data.success && !data.message.includes('window')) {
                        const msg = data.message === '(#10) Message failed to send because another app is controlling this thread now.' ? data.message.replace('send', 'update') : data.message
                        this.$notify({
                            type: 'warning',
                            title: 'Warning',
                            message: msg,
                            duration: 20000,
                        })
                    }*/
                    return res
                })
                .catch(err => {
                    console.log('err?', err)
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong, please try again later.'
                    })
                })
        },
        handleFilterChange(data) {
            this.company = data
            return this.getLatestCompanyData()
        },
    },
    watch: {
        search: function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadCars();
        },
        selectedContact: function(val, oldVal) {
            this.selectedContact = this.selectedContact;
        }
    }
};
</script>
<style scoped>
.active-recipient {
    font-weight: bolder;
    background-color: #f2f2f2 !important;
}
footer a {
    font-size: 14px;
    color: #303133;
    text-decoration: none;
}
</style>
