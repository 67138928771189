import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import bootstrap from './bootstrap';
import VueCookies from 'vue-cookies';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {en} from 'element-ui/lib/locale/lang/en';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import App from './App.vue';
import store from './store';

import Home from './components/Home.vue';
import Login from './components/Login.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import Company from './components/Company.vue';
import CompanyReports from './components/CompanyReports.vue';
import DmsCampaigns from './components/DmsCampaigns.vue';
import DigitalLeadCampaigns from './components/DigitalLeadCampaigns.vue';
import Account from './components/Account.vue';
import Messenger from './components/Messenger.vue';
import MessengerLookup from './components/MessengerLookup.vue';
import Dashboard from './components/admin/Dashboard.vue';
import Companies from './components/admin/Companies.vue';
import Cars from './components/admin/Cars.vue';
import MessengerUnreadConvoCount from './components/MessengerUnreadConvoCount.vue';
import FacebookUserProfile from './components/FacebookUserProfile.vue';
import MessengerLookupBadge from './components/MessengerLookupBadge.vue';
import SocialListening from './components/SocialListening.vue';
import WebMail from './components/WebMail.vue';
import Templates from "./components/webmail/Templates";
import Engage from "./components/Engage";
import EngageActions from "./components/EngageActions";

import User from "./components/User";

import moment from 'moment';
import * as htmlToImage from 'html-to-image'
import qs from 'qs'
import { GiphyFetch } from '@giphy/js-fetch-api'

import Dictionary from './library/dictionary'
import JsonAsXlsx from 'json-as-xlsx'
import xmljs from 'xml-js'
import CopyText from 'vue-clipboard2'
CopyText.config.autoSetContainer = true
Vue.use(CopyText)
import TreeView from "vue-json-tree-view"
Vue.use(TreeView)

Vue.prototype.moment = moment
Vue.prototype.$qs = qs
Vue.prototype.giphy = new GiphyFetch(process.env.MIX_GIPHY_API_KEY)
Vue.use(ElementUI, { locale: en });
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.prototype.$htmlToImage = htmlToImage
Vue.prototype.$ssDictionary = Dictionary
Vue.prototype.$jsonXlsx = JsonAsXlsx
Vue.prototype.$appENV = process.env.MIX_APP_ENV
Vue.prototype.$appUrl = process.env.MIX_APP_URL
Vue.prototype.$xmljs = xmljs
Vue.prototype.$appDomain = process.env.MIX_APP_DOMAIN ?? 'simpsocial.com'

let fbAppId = process.env.MIX_FACEBOOK_APP_ID
fbAppId = fbAppId.match(/\d+/g).map(Number)[0]
Vue.prototype.$fbAppId = fbAppId

let routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: false
        }
    },

    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            auth: false
        }
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { requiresAuth: true, darkMode: false }
    },

    {
        path: '/cars',
        name: 'cars',
        component: Cars,
        meta: { requiresAuth: true, darkMode: false }
    },

    {
        path: '/company',
        name: 'company',
        component: Company,
        meta: { requiresAuth: true, darkMode: false }
    },

    {
        path: '/company-reports',
        name: 'company-reports',
        component: CompanyReports,
        meta: { requiresAuth: true, darkMode: false }
    },

    {
        path: '/companies',
        name: 'companies',
        component: Companies,
        meta: { requiresAuth: true, darkMode: false }
    },

    {
        path: '/account',
        name: 'account',
        component: Account,
        meta: { requiresAuth: true, darkMode: false }
    },
    {
        path: '/messenger',
        name: 'messenger',
        component: Messenger,
        meta: { requiresAuth: true, darkMode: false }
    },
    {
        path: '/messenger-lookup',
        name: 'messenger-lookup',
        component: MessengerLookup,
        meta: { requiresAuth: true, darkMode: false }
    },
    {
        path: '/:aloware_company_id/messenger/unread/count',
        name: 'messenger-unread-count',
        component: MessengerUnreadConvoCount,
        meta: { requiresAuth: false, darkMode: false }
    },
    {
        path: '/:aloware_company_id/messenger-lookup-badge',
        name: 'messenger-lookup-badge',
        component: MessengerLookupBadge,
        meta: { requiresAuth: false, darkMode: false }
    },
    {
        path: '/messenger-user-profile',
        name: 'facebook-user-profile',
        component: FacebookUserProfile,
        meta: { requiresAuth: false, darkMode: false }
    },
    {
        path: '/dms-campaigns',
        name: 'dms-campaigns',
        component: DmsCampaigns,
        meta: { requiresAuth: true, darkMode: true }
    },
    {
        path: '/digital-leads',
        name: 'digital-leads',
        component: DigitalLeadCampaigns,
        meta: { requiresAuth: true, darkMode: true }
    },
    {
        path: '/social-listening',
        name: 'social-listening',
        component: SocialListening,
        meta: { requiresAuth: true, darkMode: false }
    },
    {
        path: '/webmail',
        name: 'webmail',
        component: WebMail,
        meta: { requiresAuth: true, darkMode: true }
    },
    {
        path: '/webmail/templates',
        name: 'webmail-templates',
        component: Templates,
        meta: { requiresAuth: true, darkMode: true }
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        meta: { requiresAuth: true, darkMode: false }
    },
    {
        path: '/user-dashboard',
        name: 'user-dashboard',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-dashboard' }
    },
    {
        path: '/user-dealerships',
        name: 'user-dealerships',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-dealerships' }
    },
    {
        path: '/user-dealerprofile',
        name: 'user-dealerprofile',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-dealerprofile' }
    },
    {
        path: '/user-dmsequity',
        name: 'user-dmsequity',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-dmsequity' }
    },
    {
        path: '/user-inventory',
        name: 'user-inventory',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-inventory' }
    },
    {
        path: '/user-contacts',
        name: 'user-contacts',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-contacts' }
    },
    {
        path: '/user-messenger',
        name: 'user-messenger',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-messenger' }
    },
    {
        path: '/user-leads',
        name: 'user-leads',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-leads' }
    },
    {
        path: '/user-emaildelivery',
        name: 'user-emaildelivery',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-emaildelivery' }
    },
    {
        path: '/user-dealervault',
        name: 'user-dealervault',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-dealervault' }
    },
    {
        path: '/user-dominion',
        name: 'user-dominion',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-dominion' }
    },
    {
        path: '/user-webchat',
        name: 'user-webchat',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-webchat' }
    },
    {
        path: '/user-users',
        name: 'user-users',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-users' }
    },
    {
        path: '/user-crm-dms-integrations',
        name: 'user-crm-dms-integrations',
        component: User,
        meta: { requiresAuth: true, darkMode: false, subPage: 'user-crm-dms-integrations' }
    }
];

const host = window.location.host;
const parts = host.split('.');
if(parts && parts.length >= 3) {
    if(parts[0] === 'engage') {
        routes = [
            {
                path: '/actions/:shortcode/:action',
                name: 'actions',
                component: EngageActions,
            },
            {
                path: '/contacts/:path',
                name: 'engage',
                component: Engage,
            },
        ]
    }
}

const router = new VueRouter({
	mode: 'history',
	routes: routes
});

router.beforeEach((to, from, next) => {
	// check if the route requires authentication and user is not logged in
	if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
		// redirect to login page
		next({ name: 'login' });
		return;
	}

	if (to.path === '/login' && store.state.isLoggedIn && to.query.redirect !== '') {
		// check if the same user is accessing
		if (store.state.token !== to.query.token) {
			store.commit('LogoutUser');
			next({
				name: 'login',
				query: to.query
			});
		} else {
			let supportedRedirects = ['account', 'cars', 'dashboard', 'company', 'company-reports', 'messenger', 'dms-campaigns', 'messenger-lookup', 'messenger-user-profile', 'digital-leads', 'webmail', 'social-listening'];

			if (supportedRedirects.includes(to.query.redirect)) {
				next({ name: to.query.redirect });
			} else {
				next({ name: 'dashboard' });
			}
		}

		return;
	}

	// if logged in redirect to dashboard
	if (to.path === '/login' && store.state.isLoggedIn && to.query.redirect == '') {
		next({ name: 'dashboard' });
		return;
	}

	if (to.path === '/' && !store.state.isLoggedIn) {
		next({ name: 'login' });
		return;
	} else if (to.path === '/' && store.state.isLoggedIn) {
		next({ name: 'dashboard' });
		return;
	}

	// check for token expiration
	if (parseInt(localStorage.getItem('token_exp')) < new Date().getTime() / 1000 && store.state.isLoggedIn) {
		store.commit('LogoutUser');
		next({ name: 'login' });
		// logout user

		//this.$router.push({ name: "login" });
		//window.location.href = '/login';
		return;
	}

	next();
});

Vue.mixin({
	data: function() {
		if (store.state.isLoggedIn) {
			var currentCompany = JSON.parse(store.state.currentCompany);
			return {
				companyApiBaseUrl: '/api/company/' + currentCompany.aloware_id,
				inIframe: window.location !== window.parent.location
			};
		}
		return {};
	},
	methods: {
        /*userHasRole(roleName) {
            var currentUser = JSON.parse(store.state.currentUser);
            return currentUser.role_names.includes(roleName);
        },*/
        userHasRole(roleNames) { // array
            var currentUser = JSON.parse(store.state.currentUser);
            let found = false;
            roleNames.forEach((role) => {
                if( currentUser.role_names.includes(role) ) {
                    found = true;
                }
            })
            return found;
        },
        getDateByFormat(format, offset = 0) {
            let now = moment()
            now = offset ? now.subtract(offset, format) : now

            if(format === 'year') {
                return now.format('Y')
            }
            return now;
        }
    }
});
Vue.filter('currency', function(value) {
    value = value.toString().replace(/,/g, '');
    if( parseFloat(value) <= 0 ) {
        return '-';
    }

	return (
		'$' +
		parseFloat(value)
			.toFixed(2)
            .toLocaleString('en')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
});
Vue.filter('commaSeparated', function(value) {
    if( parseFloat(value) <= 0 ) {
        return '0';
    }

	return (
		parseFloat(value)
			.toFixed(0)
            .toLocaleString('en')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
});
Vue.filter('timestampFormatDateTime', function(value) {
    // Check if the value is valid
    if (!value || isNaN(value)) {
        console.warn('Invalid timestamp value:', value);
        return ''; // Return an empty string for invalid input
    }

    value = parseInt(value);

    // Check if the value is in seconds (less than 10^10, approximate max Unix time in seconds)
    if (value < 1e10) {
        value *= 1000; // Convert seconds to milliseconds
    }

    // Convert the timestamp to a date
    var stillUtc = moment.utc(value);

    if (!stillUtc.isValid()) {
        console.warn('Invalid moment date created from value:', value);
        return '';
    }

    var reference = moment();
    var dateValue = stillUtc.local(); // Convert to local time

    // Define time thresholds
    var today = reference.clone().startOf('day');
    var aDayOld = reference.clone().subtract(1, 'days').startOf('day');
    var aWeekOld = reference.clone().subtract(7, 'days').startOf('day');
    var aYearOld = reference.clone().subtract(1, 'years').startOf('year');

    // Calculate time differences
    if (dateValue.isAfter(aDayOld)) {
        const diffHours = reference.diff(dateValue, 'hours');
        if (diffHours === 0) {
            return reference.diff(dateValue, 'minutes') + 'm';
        }
        return diffHours + 'h';
    } else if (dateValue.isAfter(aWeekOld)) {
        return reference.diff(dateValue, 'days') + 'd';
    } else if (dateValue.isAfter(aYearOld)) {
        return Math.floor(reference.diff(dateValue, 'weeks')) + 'w';
    } else {
        return reference.diff(dateValue, 'years') + 'y';
    }
});
Vue.filter('formatDate', function(value, withTime = false) {
    if (value) {
        let dateTimeParts= value.split(/[- :]/);
        dateTimeParts[1]--;

        var dateObject = new Date(...dateTimeParts);
        if(withTime) {
            const utcDateTime = new Date(`${dateObject.toLocaleString()} UTC`)
            return moment(utcDateTime).format('MMM DD, YYYY hh:mm A')
        }
        return dateObject.toLocaleDateString();
    }
});
Vue.filter('toSqlDate', function(value) {
    let date = new Date(value);

    return date.getFullYear() + '-' +
        (date.getMonth() +1) + '-' +
        date.getDate();
});
Vue.filter('pluralize', (amount, singular, plural) => (amount > 1) ? plural : singular);
Vue.filter('replaceHttp', function(url) {
    if(typeof url === 'string') {
        return url.replace('http://', 'https://')
    }
    return url
});
Vue.filter('truncateEllipsis', function(str, n = 27) {
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
});
Array.prototype.chunckArray = function(chunkSize) {
    let array = this
    if (!array.length) {
        return [];
    }
    return array.reduce(function (previous, current) {
        var chunk;
        if (previous.length === 0 ||
            previous[previous.length - 1].length === chunkSize) {
            chunk = [];   // 1
            previous.push(chunk);   // 2
        } else {
            chunk = previous[previous.length - 1];   // 3
        }
        chunk.push(current);   // 4
        return previous;   // 5
    }, []);
}
String.prototype.replaceArray = function(find, replace) {
    var replaceString = this;
    for (var i = 0; i < find.length; i++) {
      replaceString = replaceString.replace(find[i], replace[i]);
    }
    return replaceString;
};
// new Vue(App).$mount('#app');
new Vue({
	router,
	store,
	el: '#app',
	render: h => h(App),
	methods: {
		handleErrors(response, duration = 4500) {
			let message = 'An unexpected error occured. Please contact support';
			// filter error status codes here
			if (response && response.status) {
				switch (response.status) {
					case 404: {
						message = 'Page not found';
						break;
					}
					case 422: {
						let errors = response.data.errors;
						for (let error in errors) {
							message = errors[error] + '\n';
						}
						break;
					}
					default: {
						if (typeof response.data.message !== undefined) {
							message = response.data.message;
						}
						break;
					}
				}
			}

			this.$notify({
				title: 'Error',
				message: message,
				type: 'error',
                duration: duration,
			});
		}
	}
});
